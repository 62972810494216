<template>
  <div>
    <LoginNumber />
  </div>
</template>

<script>
import LoginNumber from '@/components/Book/LoginNumber.vue'

export default {
  components: {
    LoginNumber
  }
}
</script>

<style>

</style>
