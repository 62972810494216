<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        เข้าสู่ระบบ
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          เข้าสู่ระบบเบอร์โทรศัพท์
        </v-toolbar>
        <v-container>
          <span class="mt-5">
            ระบบได้ส่ง OTP ไปที่
          </span>
          <v-otp-input
            class="my-4"
            type="number"
            length="4"
          ></v-otp-input>
          <span>กรอกหมายเลข OTP</span>
        </v-container>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog.value = false"
          >
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {

}
</script>
